import React, {useState} from 'react'
import styled from 'styled-components'
import {BsCalendarDate, BsDownload} from "react-icons/bs"
import {BsCardImage, BsCalendar2Date} from "react-icons/bs"
import {MdAccessTimeFilled, MdLocationPin} from "react-icons/md"
import images from "./Assets/headerbg.png"
import pix from "./Assets/dom.png";
import pixs from "./Assets/3d.png";
import html2canvas from "html2canvas";
import { NavLink } from 'react-router-dom'

const Hero = () => {
    const [drop2, setDrop2] = useState<Boolean>(false)

    const [image, setImage] = useState<string>(pix);
  const [name, setName] = useState<string>("");
  const [show, setShow] = useState<Boolean>(false);
  const [show2, setShow2] = useState<Boolean>(false);

   const Show = () => {
    setDrop2(!drop2)
  }
  const Toggle = () => {
    setShow(!show)
  }
  const Toggle2 = () => {
    setShow2(!show2)
  }

  const displayPix = (e: any) => {
    const file = e.target.files[0];
    const save = URL.createObjectURL(file);
    setImage(save);
    console.log(save);
  };

  const printRef: any = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <Container>
       <Card>
         <Wrapper>
            <ImageHold>
                <Im src={pixs}/>
            </ImageHold>
            <Text>
                <h1>Tech as a Leveller in the 21st Century</h1>
            </Text>
            <P>
                <p>Technology is best when it brings people together <br />
                so come have a feel of how tech works</p>
            </P>
            <ButtonHold>
                <Button><Icons><BsCalendar2Date /></Icons> 17 Dec, 2022</Button>
                <Button><Icons><MdAccessTimeFilled /></Icons>10 AM</Button>
                <Button><Icons><MdLocationPin /></Icons>Ajif LGA</Button>
            </ButtonHold>
            <Register>
                <Button2 to="/register">Register</Button2>
            </Register>
            {show ? (
                <Drop>
                    <Res>
                        <ImageHold2>
                            <Img src={image} />
                            <ImageInput
                                id="pix"
                                type="file"
                                accept="image/jpg, image/png, image/jpeg"
                                onChange={displayPix}
                             />
                        </ImageHold2>
                        <InputHold>
                            <First>
                                <Name><h3>Name</h3></Name>
                                <Input type="text"  value={name} onChange={(e: any) => {
                                    setName(e.target.value);
                                 }}/>
                            </First>
                            <First>
                                <Name><h3>Email ID</h3></Name>
                                <Input type="email"/>
                            </First>
                            <First>
                                <Name><h3>Phone No</h3></Name>
                                <Input type="number"/>
                            </First>
                        </InputHold>
                         <Upload>
                        <ImageLabel htmlFor="pix">Upload Image</ImageLabel>
                        <Button2 to="">Submit</Button2>
                    </Upload>
                    </Res>
                </Drop>
            ) : null}
            {/* <Last></Last> */}
            
        </Wrapper>
       </Card>
    </Container>
  )
}

export default Hero
const Im = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
`

const Last = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: red; */
    position: absolute;
`
const Ic = styled.div`
    margin-left: 7px;
`
const Download = styled.div`
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000269;
    color: white;
    cursor: pointer;
    border-radius: 4px;
`
const Invite = styled.div`
    h3{
        color: white;
    }
`
const InfoHold = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Text2 = styled.div`
    h2{
        color: white;
        margin: 0;
    }
`
const Theme = styled.div`
    h3{
        color: white;
    }
`
const Final = styled.div`
    text-align: center;
    /* background-color: red; */
    h2{
        color: white;
    }
`
const ImageLabel = styled.label`
    width: 160px;
    height: 45px;
    border-radius: 7px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    background-color: black;
    margin-top: 30px;
    cursor: pointer;
    transition: all 350ms;
    :hover{
        transform: scale(1.05)
    }
     @media screen and (max-width: 392px){
        width: 130px;
        height: 40px;
    }
`;
const ImageInput = styled.input`
  display: none;
`;
const Upload = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Input = styled.input`
    height: 30px;
    width: 200px;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    background: none;
    border-radius: 3px;
    color: #F2F2F2;
    @media screen and (max-width: 320px){
       width: 100%;
    }
    @media screen and (max-width: 375px){
        width: 100%;
    }
`
const Name = styled.div`
    color: white;
    h3{
        margin: 0;
    }
    @media screen and (max-width: 375px){
       h3{
        font-size: 17px;
       }
    }
`
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
`
const First = styled.div`
    width: 95%;
    display: flex;
    /* background-color: red; */
    height: 50px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    /* flex-wrap: wrap; */
    flex-wrap: wrap;
`
const InputHold = styled.div`
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    border: 1px solid #182a38;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
     @media screen and (max-width: 320px){
        width: 240px;
        height: 100%;
    }
    @media screen and (max-width: 375px){
        width: 280px;
        height: 100%;
    }
`
const Res = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(16, 37, 59, 0.8);
    backdrop-filter: blur(10px);
`
const Drop = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    background-image: url(${images});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`
const Button2 = styled(NavLink)`
    /* border: 2px solid #fff; */
    width: 160px;
    height: 45px;
    border-radius: 7px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    background-color: black;
    margin-top: 30px;
    cursor: pointer;
    text-decoration: none;
    transition: all 350ms;
    :hover{
        transform: scale(1.05)
    }
     @media screen and (max-width: 392px){
        width: 130px;
        height: 40px;
    }
`
const Register = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Icons = styled.div`
    margin-right: 4px;
    height: 100%;
    display: flex;
    align-items: center;
`
const ButtonHold = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    @media screen and (max-width: 392px){
        flex-wrap: wrap;
    }
`
const Button = styled.div`
    border: 2px solid #fff;
    width: 160px;
    height: 45px;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    @media screen and (max-width: 606px){
        width: 130px;
        height: 35px;
    }
    @media screen and (max-width: 440px){
        width: 125px;
        height: 35px;
        font-size: 13px;
    }
`
const Card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(16, 37, 59, 0.8);
    backdrop-filter: blur(10px);
    /* padding-bottom: 10px; */
`
const P = styled.div`
    text-align: center;
    line-height: 23px;
    color: #F2F2F2;
    @media screen and (max-width: 362px){
        p{
            font-size: 14px;
        }
    }
`
const Text = styled.div`
    text-align: center;
    color: white;
    margin-top: 20px;
    h1{
        margin: 0;
    }
    @media screen and (max-width: 606px){
        h1{
            font-size: 25px;
        }
    @media screen and (max-width: 390px){
        h1{
            font-size: 23px;
        }
    }
    }
`
const Icon = styled.div`
    color: gray;
    font-size: 28px;
`
const ImageHold2 = styled.div`
    width: 180px;
    height: 180px;
    display: flex;
    border-radius: 100%;
    background-color: #F2F2F2;
    margin-top: 60px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 392px){
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
`
const ImageHold = styled.div`
    width: 180px;
    height: 180px;
    display: flex;
    border-radius: 100%;
    background-color: #F2F2F2;
    margin-top: 60px;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 392px){
        width: 140px;
        height: 140px;
        border-radius: 50%;
    }
`
const Wrapper = styled.div`
    width: 95%;
    height: 100%;
    display: flex;
    /* background-color: yellow; */
    align-items: center;
    flex-direction: column;
`

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-image: url(${images});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    /* background-color: red; */
`